import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-container',{attrs:{"breadcrumbs":"","scroll-fab":""}},[_c(VCard,{attrs:{"flat":""}},[_c('toolbar',{attrs:{"title":_vm.$t('app.route.stores'),"back-button":""}},[_c(VTextField,{staticClass:"d-none d-md-flex mx-1",staticStyle:{"width":"0"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('general.search'),"single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDivider,{attrs:{"vertical":""}}),_c(VBtn,{attrs:{"text":"","small":"","color":"green"},on:{"click":function($event){return _vm.$router.push({
            name: 'store.editor',
            params: { storeId: 'new', regionId: _vm.$route.params.regionId },
          })}}},[_c(VIcon,[_vm._v("mdi-folder-plus")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$t("general.new")))])],1),_c(VBtn,{attrs:{"text":"","small":"","color":"primary","disabled":!_vm.selected},on:{"click":function($event){return _vm.editItem()}}},[_c(VIcon,[_vm._v("mdi-file-edit-outline")]),_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])],1),_c(VDivider,{attrs:{"vertical":""}}),_c(VBtn,{attrs:{"text":"","small":"","color":"primary","disabled":!_vm.selected},on:{"click":function($event){return _vm.manageSections()}}},[_c(VIcon,[_vm._v("mdi-sitemap")]),_c('span',[_vm._v(_vm._s(_vm.$t("app.route.sections")))])],1),_c(VBtn,{attrs:{"text":"","small":"","color":"primary","disabled":!_vm.selected},on:{"click":function($event){return _vm.manageProducts()}}},[_c(VIcon,[_vm._v("mdi-barcode-scan")]),_c('span',[_vm._v(_vm._s(_vm.$t("app.route.products")))])],1),_c(VDivider,{attrs:{"vertical":""}}),(_vm.$hasRole('SUPER-ADMIN') || _vm.$hasRole('ADMIN') || _vm.$hasRole('STORE-MANAGER'))?_c(VBtn,{attrs:{"text":"","small":"","color":"red","disabled":!_vm.selected},on:{"click":function($event){return _vm.confirmDelete()}}},[_c(VIcon,[_vm._v("mdi-delete")]),_c('span',[_vm._v(_vm._s(_vm.$t("general.delete")))])],1):_vm._e()],1),_c(VCardText,{staticClass:"overflow-y-auto px-0 py-0",style:(_vm.getViewHeightStyle(84))},[_c(VDataTable,{attrs:{"dense":"","headers":_vm.headers,"items":_vm.getStores,"item-key":"id","search":_vm.search,"show-select":"","single-select":"","value":_vm.selection,"loading":_vm.loading,"loading-text":_vm.$t('messages.loading')},on:{"item-selected":_vm.selectionChanged,"click:row":_vm.selectionChanged},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
          var item = ref.item;
          var isSelected = ref.isSelected;
          var select = ref.select;
return [_c(VSimpleCheckbox,{attrs:{"on-icon":"mdi-checkbox-marked-circle","off-icon":"mdi-power-off","value":isSelected,"color":"primary","light":"","ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c(VAvatar,{staticClass:"my-1 mr-1",staticStyle:{"border":"1px solid #eee"},attrs:{"size":"32"}},[(!!item.countryShort)?_c(VImg,{attrs:{"src":("https://flagcdn.com/" + (item.countryShort) + ".svg")}}):_vm._e()],1),_c('span',[_vm._v(_vm._s(item.name))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }